import { Container, Typography } from "@material-ui/core";
import React from "react";
import logoBlanco from "../../assets/images/logo/logo-blanco.svg";
import pbAWS from "../../assets/images/fondos/pb-aws.png";
import connectAWS from "../../assets/images/logo/amazon-connect.png";
import correo from "../../assets/images/social/correo.svg";
import facebook from "../../assets/images/social/facebook.svg";
import linkedin from "../../assets/images/social/linkedin.svg";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../../assets/images/slider/background3.png";
import { Link } from 'react-router-dom';


const styles = makeStyles({
  containerFooter: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${bg})`,
    backgroundColor: "transparent",
    boxShadow: "inset 0 0 0 1000px rgb(35, 47, 62, 0.850)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "right",
  },
});

const stylesResponsive = makeStyles((theme) => ({
  footerContent: {
    fontSize: "16px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    paddingBottom: "28px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
  },
  footerLogo: {
    [theme.breakpoints.down("sm")]: {
      flex: "100%",
      textAlign: "center",
      margin: "10px",
    },
  },
  connectLogo: {
    [theme.breakpoints.up("md")]: {
      width: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
  },
  footerSocial: {
    display: " flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    marginLeft: "30px",
    marginRight: "30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}));

export default () => {
  const classes = styles();
  const respclasses = stylesResponsive();
  return (
    <div className={classes.containerFooter}>
      <Container>
        <div className={respclasses.footerContent}>
          <div
            className={respclasses.footerLogo}
            style={{
              paddingTop: "50px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img alt="logo Manantial Blanco" src={logoBlanco}></img>
            <img alt="power by AWS" src={pbAWS} style={{ height: "100px", width: "110px" }}></img>
          </div>

          <div style={{ paddingTop: "50px", padding: "20px", maxWidth: "50%" }}>
            <Typography
              variant="h6"
              component="h2"
              className="font-raleway-bold"
              style={{ color: "white" }}
            >
              Conócenos
            </Typography>
            <div style={{fontDisplay:"auto", display: "flex", flexDirection: "column" }}>
              <Link
                to="/nosotros/equipo"
                style={{
                  color: "white",
                  marginTop: "20px",
                  textDecoration: "none",
                }}
              >
                Nuestro equipo
              </Link>
              <Link
                to="/nosotros/cultura"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Nuestra cultura
              </Link>
            </div>
          </div>
          <div style={{ paddingTop: "50px", padding: "20px", maxWidth: "50%" }}>
            <Typography
              variant="h6"
              component="h2"
              className="font-raleway-bold"
              style={{ fontDisplay:"auto", color: "white" }}
            >
              Nuestros servicios
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "20px",
                  textDecoration: "none",
                }}
              >
                Bases de datos
              </Link>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Cloud
              </Link>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Monitoreo
              </Link>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Desarrollo
              </Link>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Servicios Gestionados
              </Link>
              <Link
                to="/servicios"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Outsourcing
              </Link>
            </div>
          </div>
          <div style={{ paddingTop: "50px", padding: "20px", maxWidth: "50%" }}>
            <Typography
              variant="h6"
              component="h2"
              className="font-raleway-bold"
              style={{ color: "white" }}
            >
              Partners
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link
                to="/partner/aws"
                style={{
                  color: "white",
                  marginTop: "20px",
                  textDecoration: "none",
                }}
              >
                AWS
              </Link>
              <Link
                to="/partner/oracle"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Oracle
              </Link>
              <Link
                to="/partner/veeam"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Veeam
              </Link>
            </div>
          </div>
          <div style={{ paddingTop: "50px", padding: "20px", maxWidth: "50%" }}>
            <Typography
              variant="h6"
              component="h2"
              className="font-raleway-bold"
              style={{ color: "white" }}
            >
              Ayuda
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link
                to="/ayuda/preguntas-frecuentes"
                style={{
                  color: "white",
                  marginTop: "20px",
                  textDecoration: "none",
                }}
              >
                ¿Cómo podemos ayudarte?
              </Link>
              <Link
                to="/ayuda/trabaja-con-nosotros"
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Oportunidades de trabajo en Manantial
              </Link>
              <Typography
                style={{
                  color: "white",
                  marginTop: "4px",
                  textDecoration: "none",
                }}
              >
                Central telefónica:{" "}
                <Link
                  to="tel:+016432005"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  +01 6432005
                </Link>
              </Typography>
              <div
                className={respclasses.footerLogo}
                style={{
                  paddingTop: "50px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img alt="Central Connect AWS" src={connectAWS} className={respclasses.connectLogo}></img>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ color: "white", margin: "0px" }}></hr>

        <div className={respclasses.footerSocial}>
          <div style={{ margin: "10px" }}>
            <Typography>
              Copyright © {new Date().getFullYear()} - Manantial Tecnológico |
              Todos los derechos reservados.
            </Typography>
          </div>
          <div style={{ margin: "10px" }}>
            <Link to="https://www.facebook.com/ManantialTec/" target="_blank">
              <img src={facebook} style={{ marginRight: "20px" }}></img>
            </Link>
            <Link
              to="https://www.linkedin.com/company/manantial-per%C3%BA/mycompany/"
              target="_blank"
            >
              <img alt="linkedin" src={linkedin} style={{ marginRight: "20px" }}></img>
            </Link>
            <Link to="mailto:Informes@manantial.pe" target="_blank">
              <img alt="correo" src={correo}></img>
            </Link>
          </div>
          
          <a> &nbsp;&nbsp;&nbsp;&nbsp; Calle Dionisio Derteano N°184, San Isidro, Of. 902</a>

        </div>
      </Container>
    </div>
  );
};
